<template>
    <div class="subheader py-3 py-lg-6 subheader-transparent">
    <div>
        <div class="d-flex align-items-center flex-wrap mr-1">
            <div class="d-flex align-items-baseline flex-wrap mr-5">
            <h5 class="subheader-title text-muted font-size-sm my-1 mr-3 text-uppercase">{{judul}} <span style="color: #00a551;">{{aktif}}</span> </h5>
            </div>
        </div>
    </div>
</div>
</template>
<script>
  export default {
    props: ['judul','aktif'],
  }
</script>